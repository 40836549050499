import $ from "jquery";

$(function(){

    // let header = document.querySelector('header');
    // const observer = new IntersectionObserver( 
    //     ([e]) => e.target.classList.toggle("is-stuck", e.intersectionRatio < 1),
    //     { threshold: [1] }
    //   );
      
    //   observer.observe(header);

    $(window).scroll(function() {
      var header = $('.header-top ');
      if(window.scrollY >0) {
        header.addClass('is-stuck');
      } else {
        header.removeClass('is-stuck');
      };
      });

      $( document ).ready(function() {
      userSignInPopUp();
      });
      
});

function userSignInPopUp(){
  if (window.matchMedia('(min-width: 992px)').matches)
    {
      $('.user-info.header__rightitem .header-nav__round-icon').on('click', function(e){
        e.preventDefault(); 
        $(this).parent().find('.dropdown-content-wrapper').toggleClass('active');
      });
    }



}