import $ from 'jquery';
import createRipple from '/js/components/ripple.js';
import anime from 'animejs/lib/anime.es.js';

export default class PrestathemeSlider {
    init() {
        $('[data-slick].prestathemeslider-carousel').not('.slick-initialized').each(function() {
            let self = $( this );
            if(self.data('count') === 1){
                return;
            }

            self.slick({
                "autoplay": true,
                "autoplaySpeed": 15000,
                "slidesToShow": 1,
                "arrows": true,
                "dots": true,
                "appendDots": '.dots',
                "appendArrows": '.carousel-arrow-navigation',
                "focusOnSelect": true,
                });
                
            var textWrappers = $('.slide-title .letters');
            textWrappers.each(function(){
                let words = $(this).text().split(' ');
                let output = '';
                $.each(words, function(i, word){
                    let letters = word.replace(/\S/g, "<span class='letter'>$&</span>");
                    output += `<span class="word">${letters} </span>`;
                })
                $(this).html(output);
            });
            
            let contentItems = $('#prestathemeslider .slider-content-item');
            let contentTitles = document.querySelectorAll('#prestathemeslider .slider-content-item .slide-title');

            let textAnimIn = [];
            contentTitles.forEach(function(element, index){
                textAnimIn[index] = anime({
                    loop: false,
                    autoplay: false,
                    targets: element.querySelectorAll('.letter'),
                    rotateY: [-90, 0],
                    duration: 1300,
                    delay: (el, i) => 45 * i
                });
            });

            if (contentItems.length) {
                contentItems.first().addClass('active')
                textAnimIn[0].play();
            }

            let previousSlide;

            $('.slider-content')
            .on('mouseenter', () => self.slick('slickPause'))
            .on('mouseleave', () => self.slick('slickPlay'))

        });



    }

}



