import $ from 'jquery';

$(document).ready(function () {
    mobileSearch(); 
    $(window).resize(function(){
        displayDesktopSearch()
      });

      function mobileSearch() {
        if (window.matchMedia('(max-width: 991px)').matches)
        {
            $('#mobile-search-btn').on('click', function(){
                // $('#search_widget').slideToggle(100);
                if($('#search_block_top').css("display") == "none") {
                    console.log('nie je');
                    $('#search_block_top').slideDown(100);
                }
                else {
                    $('#search_block_top').slideUp(100);
                    console.log('je');
                }
            });
        }

    }

    function displayDesktopSearch() {
        if (window.matchMedia('(min-width: 992px)').matches)
        {
            if($('#search_block_top').css("display") == "none") {
                $('#search_block_top').slideDown(100);
            };
        }
    }

    // var $searchWidget = $('[data-search-widget]'),
    //     $searchInput    = $searchWidget.find('input[type=search]'),
    //     $searchButton = $searchWidget.find('button[type=submit]'),
    //     searchURL     = $searchWidget.attr('data-search-controller-url');
    
    // $searchInput.on('input', function(){
    //     if (this.value.trim().length) {
    //         $searchButton.removeAttr('disabled');
    //     } else {
    //         $searchButton.attr('disabled', '');
    //     }
    // });
    
    /* $searchInput.autoComplete({
        minChars: 3,
        source: function (query, response) {
            $.post(searchURL, {
                s: query,
                resultsPerPage: 10
            }, null, 'json')
                .then(function (resp) {
                    response(resp.products);
                })
                .fail(response);
        },
        renderItem: function (product, search) {

            return '<div class="media autocomplete-suggestion" data-url="' + product.url + '">' +
                '<img class="mr-1" src="' + product.cover.bySize.small_default.url + '" width="' + product.cover.bySize.small_default.width + '" height="' + product.cover.bySize.small_default.height + '">' +
                '<div class="media-body py-2">' + product.name + '</div>' +
                '</div>';
        },
        onSelect: function (e, term, item) {
            e.preventDefault();
            window.location.href = item.data('url');
        }
    }); */



});
